@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 15;
    height: 10px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #ffffff;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #9a9a9a;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #656565;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-heading{
  font-size: 5vw; 
  font-family: 'Manrope';
  font-weight: 900;
  letter-spacing: -0.04em;
}

.section-heading{
  font-size: 3vw; 
  font-family: 'Manrope';
  font-weight: 700;
  letter-spacing: -0.04em;
}

.sub-heading{
  font-size: 2vw; 
  font-family: 'Manrope';
  font-weight: 800;
  letter-spacing: -0.04em;
}

.paragraph-one-bold{
  font-size: 1.5vw; 
  font-family: 'Manrope';
  font-weight: 800;
  letter-spacing: -0.04em;
}

.paragraph-one{
  font-size: 1.5vw; 
  font-family: 'Manrope';
  font-weight: 500;
  letter-spacing: -0.04em;
}

.paragraph-two-bold{
  font-size: 1.4vw; 
  font-family: 'Manrope';
  font-weight: 700;
  letter-spacing: -0.04em;
}

.paragraph-two{
  font-size: 1.4vw; 
  font-family: 'Manrope';
  font-weight: 600;
  letter-spacing: 0.00em;
}

.paragraph-three-bold{
  font-size: 1.3vw; 
  font-family: 'Manrope';
  font-weight: 900;
}

.paragraph-three{
  font-size: 1.3vw; 
  font-family: 'Manrope';
  font-weight: 500;
}

.paragraph-four{
  font-size: 1.15vw; 
  font-family: 'Manrope';
  font-weight: 400;
  letter-spacing: 0.00em;
}

.paragraph-four-bold{
  font-size: 1.15vw; 
  font-family: 'Manrope';
  font-weight: 700;
}

.paragraph-five{
  font-size: 0.95vw; 
  font-family: 'Manrope';
  font-weight: 500;
}

.paragraph-five-bold{
  font-size: 0.95vw; 
  font-family: 'Manrope';
  font-weight: 800;
}



.button{
  font-size: 1.1vw; 
  font-family: 'Manrope';
  font-weight: 700;
}

.clear{
  font-size: 1.0vw; 
  font-family: 'Manrope';
  font-weight: 400;
}

.caption{
  font-size: 1.3vw; 
  font-family: 'Manrope';
  font-weight: 400;
}


/* vertical slide animation */
.carousel-container {
  overflow: hidden;
  height: 100px; /* Adjust height as needed */
  position: relative;
  display: flex;
  flex-direction: column;
}

.carousel-slide {
  width: 100%;
  height: 95px; /* Adjust height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transition: transform 1s ease-in-out;
}

/* Image styles */
.carousel-image {
  width: 100%; /* Adjust width as needed */
  height: auto; /* Ensure aspect ratio is maintained */
}

.icon {
  width: 100%; /* Adjust width as needed */
  height: auto; /* Ensure aspect ratio is maintained */
  max-height: 100px; /* Adjust max-height as needed to fit the design */
}

.social-icon {
  width: 100%; /* Adjust width as needed */
  height: auto; /* Ensure aspect ratio is maintained */
}

.backgroundS{
  background-image: url('/src/assets/Images/backgroundS.png');
  background-size: cover;
  background-repeat: repeat;
}

.backgroundComma{
  background-image: url('/src/assets/Images/Comma.png');
  background-size: 100% 100%;
}

.backgroundJobDetails{
  background-image: url('/src/assets/Images/Gradient.png');
  background-size: contain;
}

.backgroundEmployer{
  background-image: url('/src/assets/Images/EmloyerHome.png');
  background-size: cover;
}

.drop-shadow-md{
  box-shadow: rgba(189, 189, 189, 0.35) 0px 5px 15px;
}

.drop-shadow-lg{
  box-shadow: rgba(69, 68, 68, 0.35) 0px 5px 15px;
}

.drop-shadow-xl{
  box-shadow: rgba(5, 5, 5, 0.35) 0px 5px 15px;
}

/* Partner */
.horizontal-scroll-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  height: 100px; /* Adjust height as needed */
}

.horizontal-scroll-content {
  display: flex;
}

.horizontal-scroll-content img {
  height: 100px; /* Adjust height as needed */
  width: auto;
  margin-right: 60px; /* Adjust margin to create gap between images */
}

.ellipsis-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines before truncating */
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  word-break: break-word;
}

root:has(.no-doc-scroll) {
  overflow:hidden;
}

.pre-wrap {
  white-space: pre-wrap;
}

.fixed-position-on-mobile {
  position: fixed;
  transform: translate3d(0,0,0);
}

@tailwind base;
@tailwind components;
@tailwind utilities;