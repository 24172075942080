.slick-dots li button:before {
    color: var(--dot-color);
}
  
.slick-dots li.slick-active button:before {
    color: var(--dot-color);
}

.slick-dots li {
    margin: 0 -6px;
}

.slide-container {
    padding-bottom: 20px;
}