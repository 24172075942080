@keyframes movingGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  #featured-jobCard {
    border: solid 0px transparent;
    background-image: linear-gradient(white, white), linear-gradient(to right, #9246BA, #D35DB5, #B76056, #F1CA68);
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-size: 250% 250%;
    animation: movingGradient 4s linear infinite;
    padding: 4px;
  }